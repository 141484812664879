<template>
  <div class="wrap">
    <div class="dis_flex_between">
      <div v-if="!isPast" class="tag_box">
        <el-tag v-if="myCourseShow && !isPast" class="tag_item" closable type="info" effect="plain" @click="toMyCourse"
          @close="myCourseShow = false">我的课程 >>
        </el-tag>
        <i v-if="myCourseShow" class="iconfont icon-xiangyou" />
        <!-- <el-tag
        v-if="currentTag"
        class="tag_item"
        closable
        type="info"
        effect="plain"
        @click="
          () => {
            currentTag = false;
            toMyClass();
          }
        "
        @close="
          () => {
            currentTag = false;
            toMyCourse();
          }
        "
        >{{ className }}</el-tag
      > -->
        <!-- <i v-if="currentTag" class="iconfont icon-xiangyou" /> -->
        <el-tag v-if="threeTag && !isPast" class="tag_item" closable effect="plain" @close="() => {
          threeTag = false
          toMyClass()
        }
          ">{{ className }}</el-tag>
      </div>
      <!-- <div v-if="yearKc && yearKc.length > 0 && isPast" class="year_box">
        <div v-for="(item, index) in yearKc" :key="index" class="year_item" :class="year == item ? 'on' : ''"
          @click="changeYear(item)">
          {{ item }}
        </div>
        <div class="clear_float" />
      </div>
      <div v-else />
      <div class="past">
        <el-button @click="goPast">{{
          !isPast ? '往期课程 >>' : '返回当前课程 >>'
        }}</el-button>
      </div> -->
    </div>
    <!-- <div v-if="yearKc && yearKc.length > 0&&isPast " class="select_kcm">
      <div :class="showAllKc?'kcm':'kcm kcms'">
        <div v-for="(item,index) in courseNameList" :key="index" :class="kid == item.kcId?'choose_true':''"
          @click="chooseKcm(item)">
          {{ item.kcName }}
        </div>
      </div>
      <div class="allkcm" @click="showAllKc=!showAllKc">{{ showAllKc?'全部':'收起' }}
        <i class="iconfont icon-xiangxia" :class="!showAllKc? 'xiangshang':''" />
      </div>
    </div> -->
    <!-- <div class="kc_name-box">
      <div class="kc_name">{{ kcName }}</div>
      <div class="screen_box">
        <div class="item" :class="{ on: courseType == 1 }" @click="changeType(1)">
          直播课
        </div>
        <div class="item" :class="{ on: courseType == 2 }" @click="changeType(2)">
          录播课
        </div>
        <div class="item" :class="{ on: courseType == 5 }" @click="changeType(5)">
          面授课
        </div>
      </div>
    </div> -->
    <div v-if="!isPast" class="course_box">

      <div v-for="(item, index) in smallList" :key="index" class="course_item xbkcourse_item">
        <div class="top_box">
          <div class="img_box">
            <img class="item_img" :src="item.coverPicture" alt="">
            <div class="tag tagxbk">
              <img v-if="item.productLine == 3" src="@/assets/img/smallban/tips.png" alt="">
              <img v-else src="@/assets/img/smallban/tipss.png" alt="">
            </div>
          </div>
          <div class="text_box">
            <div class="left">
              <div style="display:flex" class="name">
                <div class="classType">直播课</div> {{ item.name }}
              </div>
              <div v-if="item.type == 5" class="name">
                上课地点：{{ item.attendPlace }}
              </div>
              <div style="display:flex">
                <div class="tag">
                  共{{ item.sections ? item.sections.length : "0" }}章
                </div>
                <div class="tag">
                  {{ item.ksTime ? item.ksTime : "0" }}课时
                </div>
              </div>

              <div class="learning_rate">
                <div class="rate">
                  <img class="icon_img" src="@/assets/img/homeSeventh/television2.png" alt="">
                  <div>
                    <span style="color: #777777; font-size: 14px">到课率</span>
                    <span style="color: #4a6af0; margin-left: 3px">{{ item.arriveRate }}%</span>
                  </div>
                </div>
                <!-- <div v-if="!itype !== 5" class="rate">
                  <img
                    class="icon_img"
                    src="@/assets/img/homeSeventh/book.png"
                    alt=""
                  />
                  <div>
                    <span style="color: #777777; font-size: 14px">学习率</span>
                    <span style="color: #4a6af0; margin-left: 3px"
                      >{{
                        item.type == 1 ? item.live : item.recordStudy
                      }}%</span
                    >
                  </div>
                </div> -->
              </div>
            </div>
            <div class="right_btn">
              <div class="right_btn_class" @click="seeCourse(item)">
                查看课程<i class="iconfont icon-xiangxia" :class="{ xiangshang: item.open }" />
              </div>
              <div class="right_btn_course">
                <div v-if="item.courseDocFlag == 1" @click="kczl(item)">
                  课程资料
                </div>
                <div v-if="item.courseTestFlag == 1" @click="kccs(item)">
                  课程测试
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="item.open" class="bottom_box bottom_boxs bottom_boxss">
          <div v-for="(child, childIndex) in item.chapterList" :key="childIndex" class="chapter_item2">
            <div class="left_box">
              <div class="title_box">
                <img style="
                                display: inline-bloc;
                                width: 18px;
                                height: 18px;
                                margin-right: 8px;
                                vertical-align: middle;
                              " src="@/assets/img/smallban/live.png" alt="">
                <div class="title" @click.stop="checkxbk(child, item)">
                  {{ child.sectionName }}
                </div>
                <div :class="child.isLiveStatus == 0 ? 'tag tags' : 'tag'">
                  <template v-if="child.liveStatus == 1">
                    <img class="tag_img1" src="@/assets/img/smallban/live1.png" alt="">
                    <!-- <span>直播中</span> -->
                  </template>
                  <template v-else-if="child.liveStatus == 0">
                    <img class="tag_img2" src="@/assets/img/smallban/live2.png" alt="">
                    <!-- <span>未开始</span> -->
                  </template>
                  <template v-else-if="child.liveStatus == 2">
                    <img class="tag_img3" src="@/assets/img/smallban/live4.png" alt="">
                    <!-- <span style="color: #ffac87">回放生成</span> -->
                  </template>
                  <template v-else-if="child.liveStatus == 3">
                    <img class="tag_img3" src="@/assets/img/smallban/live3.png" alt="">
                    <!-- <span>回放</span> -->
                  </template>
                </div>
              </div>
              <div class="time">
                <!-- <img class="time_img" src="@/assets/img/homeSeventh/clockAndWatch.png" alt=""> -->
                <span v-if="child.startTime">{{ child.startTime | format('yyyy.MM.dd HH:mm') }}</span>
                <!-- <span>-</span> -->
                <!-- <span>{{ child.endTime | format("HH:mm") }}</span> -->
              </div>
              <!-- <div class="progress_box">
                <div class="progress_bar">
                  <div class="schedule" :style="`width: ${child.studyRate}%`" />
                </div>
                <span class="text">{{ child.studyRate }}%</span>
              </div> -->
            </div>
            <!-- 产品线 1 普通线 2 精品线  3 专属线 4 普通线加精品线  5 1v1 考研班型 -->
            <div v-if="item.productLine !== '1'" class="right_box">
              <!-- <div
                v-if="child.teachList && child.teachList.length > 0"
                class="btn_item"
                @click.stop="goData(child, 2, item)"
              >
                随堂讲义
              </div> -->
              <!-- <div
                v-if="child.testList && child.testList.length > 0"
                class="btn_item"
                @click.stop="cgeckTask(child)"
              >
                随堂测试
              </div> -->
              <div v-if="child.docList && child.docList.length > 0" class="btn_item2"
                @click.stop="goData(child, 9, item)">
                随堂讲义
              </div>
              <img v-if="child.isArrive == 1" class="attendance" src="@/assets/img/smallban/arriveRate1.png" alt="">
              <img v-else class="attendance" src="@/assets/img/smallban/arriveRate2.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="(!smallList || smallList.length == 0)" class="course_box">
      <el-empty description="暂无数据" />
    </div>
    <el-dialog title="课程资料" :visible.sync="dialogVisible">
      <course-data v-if="dialogVisible" :id="kid" :type="item.type" :is-purchase="true" />
    </el-dialog>
    <el-dialog title="课程测试" :visible.sync="dialogVisibles">
      <course-task v-if="dialogVisibles" :id="kid" :type="item.type" :is-purchase="true" />
    </el-dialog>
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : '' }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName : ''
          }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfo } from '@/api/cookies'
import {
  selectRateByKeIdCourseTypeStudentId,
  selectChapterRateByStatusAndkeIdStudentId,
  selectChapterRateBykeIdStudentIdGroupId, selectRateByClassIdYearStudentIdPC
} from '@/api/mykecheng'
import Vue from 'vue'
import CourseData from '@/components/Know/course/courseData.vue'
import courseTask from '@/components/Know/course/courseTask.vue'
import { AnswerClient } from '@/api/task/answerClient'
const answerClient = new AnswerClient()
import { courseCheckPlay } from '@/api/know'
import { Know } from '@/api/know'
const know = new Know()
export default {
  components: { CourseData, courseTask },
  data() {
    return {
      userInfo: null,
      courseType: 1,
      isPast: false,
      kcId: null,
      className: '',
      myCourseShow: true,
      currentTag: true,
      threeTag: true,
      kcName: '',
      list: [],
      smallList: [],
      chapterList: [],
      yearKc: [],
      gchapterVOList: [],
      courseNameList: [],
      kid: null,
      dialogVisible: false,
      dialogVisibles: false,
      lookDeatil: null,
      showLookDetail: false,
      showAllKc: true
    }
  },
  created() {
    this.userInfo = getInfo()
    this.kcId = this.$route.query.kcId
    this.coursesId = this.$route.query.coursesId
    this.year = this.$route.query.year
    this.id = this.$route.query.id
    this.className = this.$route.query.className
    this.kcName = this.$route.query.kcName
    this.type = this.$route.query.type
    this.itype = this.$route.query.itype
    this.kcbClassId = this.$route.query.kcbClassId
    if (this.itype == 1 || this.itype == 3) {
      this.courseType = 1
    } else if (this.itype == 5) {
      this.courseType = 5

    } else {
      this.courseType = 2
    }

    this.getList()
  },
  methods: {
    async getCourseNameList() {
      const data = {
        classesId: this.id,
        year: this.year,
        studentId: this.userInfo.id,
        type: this.type
      }
      await selectRateByClassIdYearStudentIdPC(data).then((res) => {
        this.courseNameList = res.msg
      })
    },
    async getYear() {

      // if (this.itype == 5) {
      //   know
      //     .selectYearByFaceId({
      //       keId: this.kId,
      //       studentId: this.userInfo.id,
      //       classesId: this.type == 1 ? this.id : this.kcbClassId,
      //       packageId: this.type == 16 ? this.id : null,
      //     })
      //     .then((res) => {
      //       if (res.code == 0) {
      //         this.yearKc = res.msg;
      //         this.year = res.msg[0];
      //         this.getList();
      //       }
      //     });
      // } else {
      await know
        .classesTable(
          this.type == 1 ? this.id : this.kcbClassId,
          this.userInfo.id,
          this.kcId
          // packageId: this.type == 16 ? this.id : null,
        )
        .then((res) => {
          if (res.code == 0) {
            const datetime = new Date()
            const curyear = datetime.getFullYear()
            // this.yearKc = res.data.yearNames
            this.yearKc = res.data.yearNames.filter(item => item != curyear)
            this.year = this.yearKc[0]
          }
        })
      // }
    },
    async goPast(params) {
      this.isPast = !this.isPast
      this.kid = ''

      if (this.isPast) {
        this.kid = ''
        await this.getYear()
        await this.getCourseNameList()

        await this.getPastList()
      } else {
        this.getList()
      }
    },
    async getPastList() {
      const res = await know.pastCourseList(
        this.type == 1 ? this.id : this.kcbClassId,
        this.userInfo.id,
        this.year,
        this.kcId ? this.kcId : undefined
      )
      if (res.code == 0) {
        this.list = res.data
      }
    },
    async chooseKcm(item) {
      this.kid = item.kcId
      await this.getPastList()
    },
    async getList() {
      // know
      //   .classesNewCourseList(
      //     this.type == 1 ? this.id : this.kcbClassId,
      //     this.userInfo.id,
      //     undefined,
      //     undefined,
      //     this.kcId ? this.kcId : undefined,
      //     this.courseType ? this.courseType : undefined
      //   )
      //   .then((res) => {
      //     this.list = res.data
      //     // this.list.forEach((e) => {
      //     //   if (e.gchapterVOList && e.gchapterVOList.length > 0) {
      //     //     e.isshow = false;
      //     //   }
      //     // });
      //   })
      await know
        .getPrivateClassList(
          this.type == 1 ? this.id : this.kcbClassId,
          this.userInfo.id
        )
        .then((res) => {
          if (res.code != 500) {
            this.smallList = res.data
          }
        })
    },
    toMyCourse() {
      this.$router.push({
        path: `/seventhPage/mycurriculum`
      })
    },
    toMyClass() {
      this.$router.go(-1)
    },
    changeType(val) {
      this.courseType = val
      this.getList()
    },
    seeCourse(item) {
      if (item.sections && item.sections.length > 0) {
        item.chapterList = item.sections
      }
      this.kid = item.kid
      if (!item.open) {
        this.list.forEach((e) => {
          this.$set(e, 'open', false)
          if (e.chapterList && e.chapterList.length >= 0) {
            e.chapterList.forEach((el) => {
              this.$set(el, 'isshow', false)
            })
          }
        })
      }
      this.$set(item, 'open', !item.open)
      if (item.chapterList && item.chapterList.length) return
      const data = {
        keId: item.id,
        courseType: item.type,
        studentId: this.userInfo.id
      }
      selectChapterRateByStatusAndkeIdStudentId(data).then((res) => {
        if (res.code == 0) {
          this.$set(item, 'chapterList', res.msg)
        }
      })
    },
    /* 随堂资料 */
    goData(item, type, items) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (type == 9) {
        window.open(
          `/data/dataPackageDetailXbk?classesId=${this.id}&courseId=${items.id}&sectionId=${item.id}`,
          '_blank'
        )
      } else {
        window.open(
          `/data/dataPackageDetails?courId=${items.id}&classesId=${this.id
          }&courseType=${items.type}&type=${this.courseType == 1 ? 2 : 1
          }&docType=${type}&ChatId=${item.id}&groupId=${item.groupId ? item.groupId : undefined
          }`,
          '_blank'
        )
      }
    },
    // 课堂作业
    cgeckTask(item) {
      const title = item.testList[0].sjName
      const sId = item.testList[0].cContactid
      answerClient.selectPaperSubjectList(sId).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExams(title, sId, 2, 1, true, res.data.paper.seeNum)
        }
      })
    },
    ifshow(row, i, item) {
      this.gchapterVOList = []
      if (i == 1) {
        selectChapterRateBykeIdStudentIdGroupId({
          keId: item.id,
          groupId: row.id,
          studentId: this.userInfo.id
        }).then(async (res) => {
          if (res.code !== 0) return
          await item.chapterList.forEach((e) => {
            e.isshow = false
          })
          this.gchapterVOList = res.msg
          row.isshow = true
        })
      } else {
        row.isshow = false
      }
      this.$forceUpdate()
    },
    // 观看重播按钮  切换课程章节播放(直播)
    check(item, group) {
      // debugger

      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }

      // if (this.isFreeze == 1) {
      //   this.$message.error('课程已冻结')
      //   return
      // }
      if (item.isLive == 0) {
        this.$message.error('课程还未到开播时间')
        return
      }
      if (item.isLive == 2) {
        this.$message.error('课程直播结束')
        return
      }

      const sub = {
        id: item.id,
        classId: this.id,
        coursePackageId: this.id,
        startTime: item.startTime,
        chapterName: item.name,
        typekc: this.type,
        classProductLine: group.productLine
      }
      for (const key in sub) {
        if (!sub[key] && sub[key] !== 0) {
          delete sub[key]
        }
      }
      const data = Vue.prototype.export(sub)
      // this.$router.push({
      //   path: `/livebofang?${data}`,
      // })
      courseCheckPlay({
        courseType: this.courseType,
        userId: this.userInfo.id,
        chapterId: item.id,
        source: 1
      }).then((res) => {
        if (res.code == 0) {
          const routeUrl = this.$router.resolve({
            path: `/livebofang?${data}`
          })
          window.open(routeUrl.href, '_blank')
        } else {
          this.lookDeatil = res.msg
          this.showLookDetail = true
        }
      })
    },
    // 观看重播按钮  切换课程章节播放(直播)小班课
    checkxbk(item, group) {
      // debugger


      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }

      // if (this.isFreeze == 1) {
      //   this.$message.error('课程已冻结')
      //   return
      // }
      if (item.liveStatus == 0) {
        this.$message.error('课程还未到开播时间')
        return
      }
      if (item.liveStatus == 2) {
        this.$message.error('课程直播结束')
        return
      }

      const sub = {
        courseId: item.courseId,
        classesId: this.id,
        sectionId: item.id,
        liveStatus: item.liveStatus
      }
      for (const key in sub) {
        if (!sub[key] && sub[key] !== 0) {
          delete sub[key]
        }
      }
      const data = Vue.prototype.export(sub)
      // this.$router.push({
      //   path: `/livebofang?${data}`,
      // })
      courseCheckPlay({
        courseType: this.courseType,
        userId: this.userInfo.id,
        chapterId: item.id,
        source: 1
      }).then((res) => {
        if (res.code == 0) {
          const routeUrl = this.$router.resolve({
            path: `/livebofangxbk?${data}`
          })
          window.open(routeUrl.href, '_blank')
        } else {
          this.lookDeatil = res.msg
          this.showLookDetail = true
        }
      })
    },
    // 观看回放（录播）
    checks(item, group, index) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (this.isFreeze == 1) {
        this.$message.error('课程已冻结')
        return
      }

      if (this.isOutsourcing == 1) {
        this.ifDialog = true
        this.dialogList = item
      } else {
        const sub = {
          id: item.id,
          uservideoConfig: item.videoConfig,
          courseId: this.kid,
          classId: this.id,
          groupId: this.kid,
          typebs: 2,
          chapterName: item.name,
          typekc: this.type,
          classProductLine: group.productLine
        }
        const data = Vue.prototype.export(sub)
        courseCheckPlay({
          courseType: this.courseType,
          userId: this.userInfo.id,
          chapterId: item.id,
          source: 1
        }).then((res) => {
          if (res.code == 0) {
            this.$router.push(`/dianbofang?${data}&openIndex=${index}`)
          } else {
            this.lookDeatil = res.msg
            this.showLookDetail = true
          }
        })
      }
    },
    closeLook() {
      this.showLookDetail = false
    },
    kczl(item) {
      this.kid = item.id
      this.dialogVisible = true
    },
    kccs(item) {
      this.kid = item.id
      this.dialogVisibles = true
    },
    async changeYear(item) {

      this.year = item
      this.kid = ''

      await this.getCourseNameList()

      await this.getPastList()
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  padding-top: 24px;

  .tag_box {
    margin-top: 8px;
    margin-bottom: 7px;

    .tag_item {
      cursor: pointer;
    }

    .icon-xiangyou {
      color: #aaaaaa;
      margin: 0 12px;
      font-size: 12px;
    }
  }

  .past {
    .el-button {
      // width: 132px;
      height: 38px;
      background: #ff5e51;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      border: none !important;
    }
  }

  .kc_name-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-top: 10px;

    .kc-name {
      max-width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #333333;
      font-weight: 600;
    }

    .screen_box {
      display: flex;
      width: 203px;
      height: 38px;
      border-radius: 6px;
      background-color: #f5f7f9;
      padding: 4px;
      box-sizing: border-box;
      color: #333333;
      font-size: 14px;

      .item {
        width: 98px;
        height: 30px;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
      }

      .on {
        background-color: #ffffff;
        border-radius: 6px;
        color: #4a6af0;
      }
    }
  }

  .course_box {
    margin-top: 18px;

    .course_item {
      padding: 14px 20px;
      width: 772px;
      min-height: 133px;
      box-shadow: 0px 0px 12px 1px rgba(204, 204, 204, 0.34);
      border-radius: 8px 8px 8px 8px;
      background-color: #ffffff;
      margin-bottom: 24px;

      .top_box {
        display: flex;

        // margin-bottom: 16px;
        .img_box {
          position: relative;

          .item_img {
            width: 157.5px;
            height: 105px;
            border-radius: 8px;
            margin-right: 14px;
          }

          .tag {
            width: 45px;
            height: 21px;
            line-height: 21px;
            border-radius: 8px 0 8px 0;
            background-image: url("~@/assets/img/smallban/bac.png");
            background-size: 45px 21px;
            color: #ffffff;
            text-align: center;
            font-size: 11px;
            position: absolute;
            top: 0;
            left: 0;

            img {
              transform: translateY(-3px);
              width: 60px;
            }
          }

          .tagxbk {
            background-image: none;
          }
        }

        .text_box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .left {
            width: 330px;

            .name {
              width: 450px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #333333;
              margin-bottom: 12px;
            }

            .classType {
              padding: 2px 8px;
              border-radius: 4px 4px 4px 4px;
              opacity: 1;
              border: 1px solid #ff7b1c;
              font-size: 12px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #ff7b1c;
              margin-right: 8px;
            }

            .tag {
              // width: 52px;
              width: fit-content;
              padding: 0 7px;
              // height: 20px;
              background-color: #fff2e9;
              color: #ff7b1c;
              font-size: 12px;
              text-align: center;
              // line-height: 20px;
              margin-bottom: 16px;
              border-radius: 4px;
              margin-right: 10px;
            }

            .learning_rate {
              display: flex;

              .rate {
                display: flex;
                align-items: center;
                margin-right: 20px;

                .icon_img {
                  width: 15px;
                  height: 14px;
                  margin-right: 3px;
                }
              }
            }
          }

          .right_btn {
            flex-shrink: 0;
          }

          .right_btn_class {
            height: 20px;
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #626e7c;
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .icon-xiangxia {
              font-size: 10px;
              margin-left: 6px;
              display: inline-block;
            }

            .xiangshang {
              transform: rotate(180deg);
              transition: 200ms;
              transform-origin: 50% 50%;
            }
          }

          .right_btn_course {
            display: flex;
            justify-content: flex-end;
            margin-top: 16px;

            >div {
              margin-right: 14px;
              width: 93px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              border-radius: 4px 4px 4px 4px;
              opacity: 1;
              border: 1px solid #1061ff;
              cursor: pointer;
              font-size: 14px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #1061ff;
            }

            >div:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .bottom_box {
        // width: 764px;
        background-color: #f9f9f9;
        border-radius: 8px;
        margin-top: 15px;

        .chapter_item {
          height: 76px;
          width: 760px;
          padding: 16px;
          border-bottom: 1px solid #eeeeee;
          display: flex;
          justify-content: space-between;

          .left_box {
            width: calc(100% - 300px);

            .title_box {
              margin-bottom: 16px;
              display: flex;
              align-items: center;

              .tag {
                display: inline-block;
                padding: 2px 0px;
                height: 22px;
                border-radius: 0 8px 0 8px;
                background: linear-gradient(132deg, #fff3eb 0%, #ffedeb 100%);
                margin-right: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #ff6e2d;
                font-size: 12px;
                flex-shrink: 0;
                margin-left: 5px;

                .tag_img1 {
                  width: 68px;
                  margin-right: 3px;
                }

                .tag_img2 {
                  width: 72px;
                  margin-right: 3px;
                }

                .tag_img3 {
                  width: 58px;
                  margin-right: 3px;
                }
              }

              .title {
                color: #333333;
                max-width: 350px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                cursor: pointer;
                margin-right: 3px;
              }
            }

            .time {
              color: #aaaaaa;
              font-size: 14px;
              display: flex;
              align-items: center;
              margin-bottom: 19px;

              .time_img {
                width: 14px;
                height: 14px;
                margin-right: 6px;
                flex-shrink: 0;
              }
            }

            .progress_box {
              display: flex;
              align-items: center;

              .progress_bar {
                width: 390px;
                height: 8px;
                background-color: #e5e5e5;
                border-radius: 10px;

                .schedule {
                  // background: linear-gradient(90deg, #a5bdff 0%, #2871ff 100%);
                  background: #2871ff;

                  height: 8px;
                  width: 0;
                  border-radius: 10px;
                }
              }

              .text {
                font-size: 12px;
                color: #2871ff;
                margin-left: 8px;
              }
            }
          }

          .right_box {
            position: relative;
            display: flex;
            align-items: center;

            .btn_item {
              width: 80px;
              height: 30px;
              border-radius: 4px;
              border: 1px solid #4a6af0;
              color: #4a6af0;
              font-size: 13px;
              text-align: center;
              line-height: 30px;
              margin-left: 14px;
              cursor: pointer;
            }

            .attendance {
              position: absolute;
              right: 0;
              top: 0;
              width: 53px;
              height: 20px;
            }
          }
        }

        .chapter_item2 {
          height: 78px;
          width: 746px;
          padding: 17px 16px 0px 20px;
          // border-bottom: 1px solid #eeeeee;
          background: #f9f9f9;
          display: flex;
          justify-content: space-between;
          margin-bottom: 13px;

          border-radius: 8px 8px 8px 8px;

          .left_box {
            .title_box {
              margin-bottom: 9px;
              display: flex;
              align-items: center;

              .tag {
                display: inline-block;
                padding: 2px 0px;
                height: 22px;
                border-radius: 0 8px 0 8px;
                background: linear-gradient(132deg, #fff3eb 0%, #ffedeb 100%);
                margin-right: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #ff6e2d;
                font-size: 12px;
                flex-shrink: 0;

                .tag_img1 {
                  width: 68px;
                  margin-right: 3px;
                }

                .tag_img2 {
                  width: 72px;
                  margin-right: 3px;
                }

                .tag_img3 {
                  width: 58px;
                  margin-right: 3px;
                }
              }

              .title {
                color: #333333;
                max-width: 350px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                cursor: pointer;
                margin-right: 3px;
              }
            }

            .time {
              color: #aaaaaa;
              font-size: 14px;
              display: flex;
              align-items: center;
              margin-bottom: 19px;

              .time_img {
                width: 14px;
                height: 14px;
                margin-right: 6px;
                flex-shrink: 0;
              }
            }

            .progress_box {
              display: flex;
              align-items: center;

              .progress_bar {
                width: 390px;
                height: 8px;
                background-color: #e5e5e5;
                border-radius: 10px;

                .schedule {
                  // background: linear-gradient(90deg, #a5bdff 0%, #2871ff 100%);
                  background: #2871ff;
                  height: 8px;
                  width: 0;
                  border-radius: 10px;
                }
              }

              .text {
                font-size: 12px;
                color: #999999;
                margin-left: 8px;
              }
            }
          }

          .right_box {
            position: relative;
            display: flex;
            align-items: center;

            .btn_item2 {
              width: 72px;
              height: 24px;
              border-radius: 4px;
              border: 1px solid #4a6af0;
              color: #4a6af0;
              font-size: 13px;
              text-align: center;
              line-height: 24px;
              margin-left: 14px;
              cursor: pointer;
            }

            .btn_item {
              width: 80px;
              height: 30px;
              border-radius: 4px;
              border: 1px solid #4a6af0;
              color: #4a6af0;
              font-size: 13px;
              text-align: center;
              line-height: 30px;
              margin-left: 14px;
              cursor: pointer;
            }

            .attendance {
              position: absolute;
              right: 0;
              top: -21px;
              width: 53px;
              height: 20px;
            }
          }
        }

        .chapter_item:last-child {
          border-bottom: 0;
        }
      }

      .bottom_boxs {
        background-color: #fff !important;

        .chapter_item {
          // background-color: #f9f9f9;
        }

        .zhang {
          margin-bottom: 10px;
        }

        .zhang_item {
          display: flex;
          align-items: center;
          background-color: #f9f9f9;
          padding: 14px 0 14px 20px;
          border-radius: 6px;

          .time_img {
            display: block;
            width: 14px;
            height: 12px;
            margin-right: 8px;
            flex-shrink: 0;
          }

          .zhang_item_name {
            width: 660px;
          }

          .zhang_item_last {
            flex-shrink: 0;
            width: 40px;
            font-size: 12px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #aaaaaa;
            cursor: pointer;

            .iconfont {
              font-size: 12px;
            }
          }
        }

        .chapter_items {
          height: 82px;

          .left_box {
            .title_box {
              // margin-bottom: 27px;
            }

            .title {
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #333333;
            }
          }

          .progress_box {
            margin-top: 17px;
          }
        }
      }

      .bottom_boxss {
        .chapter_item {
          border-bottom: 0 solid #eeeeee;
          background-color: #f9f9f9;
          padding-left: 20px;
          border-radius: 8px 8px 8px 8px;
        }

        .right_box {
          .btn_item {
            margin-top: 7px;
          }
        }
      }
    }
  }
}

::v-deep .el-tag--plain {
  color: #4a6af0;
  border-color: #4a6af0;

  i {
    color: #4a6af0;
  }
}

::v-deep .el-tag {
  height: 28px;
  line-height: 28px;
}

::v-deep .el-tag--info {
  border-color: #e9e9eb;
  color: #909399;
}

.tags {
  background: linear-gradient(132deg, #ececec 0%, #e5e5e5 100%) !important;
  color: #777777 !important;
}

.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;

    .contents {
      width: 380px;
      margin: 20px auto;

      &>span {
        color: red;
      }
    }

    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}

.year_box {
  padding: 4px;
  height: 38px;
  // margin-top: 24px;
  min-width: 140px;
  background-color: #f5f7f9;
  display: inline-block;
  border-radius: 6px;
  font-size: 14px;

  // margin-bottom: 5px;
  .year_item {
    width: 132px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    float: left;
    cursor: pointer;
  }

  .on {
    background-color: #ffffff;
    color: #4a6af0;
    border-radius: 6px;
    transition: 200ms;
    font-weight: 600;
  }

  .clear_float {
    clear: both;
  }
}

.xbkcourse_item {
  .bottom_box {
    background: #f9f9f9 !important;
  }
}

.select_kcm {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 0;

  .kcm {
    height: 35px;
    display: flex;
    max-width: calc(100%-80px);
    overflow: hidden;
    flex-wrap: wrap;

    >div {
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #aaaaaa;
      padding: 2px 5px;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #777777;
      margin-right: 10px;
      cursor: pointer;
      margin-bottom: 5px;
      margin-top: 5px;
    }

    .choose_true {
      border: 1px solid #ff5e51;
      color: #ff5e51;
    }
  }

  .kcms {
    height: auto;
  }

  .allkcm {
    flex-shrink: 0;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    font-family: Microsoft YaHei-Semibold, Microsoft YaHei;
    font-weight: 400;
    color: #333333;

    cursor: pointer;

    .iconfont {
      font-size: 12px !important;
      margin-left: 5px;
      color: #707070;
    }
  }
}

.xiangshang {
  display: inline-block;
  transform: rotate(180deg);
  transition: 200ms;
  transform-origin: 50% 50%;
}
</style>
